import * as THREE from 'three'
import { EffectComposer, RenderPass, SMAAPass } from 'postprocessing'
import * as dat from 'dat.gui'
import OrbitControlsConstructor from 'three-orbit-controls'

import Time from './Time.js'
import Sizes from './Sizes.js'
import Cursor from './Cursor.js'
import Loader from './Loader.js'
import Origami from './Origami.js'

import model from './models/a-1.obj'

import smaaArea from './smaa/area.png'
import smaaSearch from './smaa/search.png'

const OrbitControls = OrbitControlsConstructor(THREE)

export default class Experience
{
    /**
     * Constructor
     */
    constructor()
    {
        this.time = new Time()
        this.sizes = new Sizes()
        this.cursor = new Cursor()
        this.$canvas = document.querySelector('.js-experience-canvas')

        this.debug = true
        this.useComposer = true

        this.setUI()
        this.setLoading()
    }

    setRoute()
    {
        this.route = {}
        this.route.slug = this.routeSlug
        this.route.oldSlug = null
    }

    updateRoute(_route)
    {
        this.route.oldSlug = this.route.slug
        this.route.slug = _route

        // Is loaded
        if(this.loading.step === 2)
        {
            // Experience animations
            if(this.route.slug === 'slider' && this.route.oldSlug === 'home')
            {
                this.world.playIntro()
            }

            if(this.route.slug === 'protect')
            {
                this.world.playFocus()
            }

            if(this.route.slug === 'slider' && this.route.oldSlug === 'protect')
            {
                this.world.quitFocus()
            }

            // Barrels
            this.world.scenes.protect.barrels.reset()
        }
    }

    /**
     * Set UI
     */
    setUI()
    {
        if(this.debug)
        {
            this.ui = new dat.GUI()
            this.ui.width = 480
        }
    }

    /**
     * Set loading
     */
    setLoading()
    {
        this.loading = {}
        this.loading.step = 0
        this.loading.loader = new Loader()

        // Load first assets
        this.loading.loader.load(smaaArea, 'smaaArea', 'image')
        this.loading.loader.load(smaaSearch, 'smaaSearch', 'image')
        this.loading.loader.load(model, 'model', 'obj')

        // Load end
        this.loading.loader.on('end', () =>
        {
            // Set environment
            this.setEnvironment()
        })
    }

    /**
     * Set environment
     */
    setEnvironment()
    {
        // Scene
        this.scene = new THREE.Scene()

        // Renderer
        this.renderer = new THREE.WebGLRenderer({ alpha: true, canvas: this.$canvas })
        this.renderer.sortObjects = false
        this.renderer.shadowMap.enabled = true
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap // PCFSoftShadowMap | PCFShadowMap
        this.renderer.setClearColor(0x000000, 1)
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        this.renderer.setSize(this.sizes.viewport.width, this.sizes.viewport.height)

        // Camera
        this.camera = new THREE.PerspectiveCamera(75, this.sizes.width / this.sizes.height, 1, 100)
        this.camera.position.set(- 5, 5, - 5)
        this.camera.lookAt(new THREE.Vector3())

        // Controls
        this.controls = new OrbitControls(this.camera, this.$canvas)

        // World
        this.setWorld()

        // // Dummy
        // this.dummy = new THREE.Mesh(new THREE.BoxBufferGeometry(1, 1, 1, 1, 1, 1), new THREE.MeshNormalMaterial())
        // this.scene.add(this.dummy)

        // Composer
        this.composer = new EffectComposer(this.renderer, { depthTexture: true })

        // Passes
        this.passes = {}
        this.passes.list = []
        this.passes.updateRenderToScreen = () =>
        {
            let enabledPassFound = false

            for(let i = this.passes.list.length - 1; i >= 0; i--)
            {
                const pass = this.passes.list[i]

                if(pass.enabled && !enabledPassFound)
                {
                    pass.renderToScreen = true
                    enabledPassFound = true
                }
                else
                {
                    pass.renderToScreen = false
                }
            }
        }

        this.passes.render = new RenderPass(this.scene, this.camera)
        this.composer.addPass(this.passes.render)
        this.passes.list.push(this.passes.render)

        this.passes.smaa = new SMAAPass(this.loading.loader.resources.smaaSearch, this.loading.loader.resources.smaaArea)
        this.passes.smaa.enabled = window.devicePixelRatio <= 1
        this.composer.addPass(this.passes.smaa)
        this.passes.list.push(this.passes.smaa)

        this.passes.updateRenderToScreen()

        // Time tick
        this.time.on('tick', () =>
        {
            // Renderer
            if(this.useComposer)
            {
                this.composer.render(this.scene, this.camera)
            }
            else
            {
                this.renderer.render(this.scene, this.camera)
            }
        })

        // Resize event
        this.sizes.on('resize', () =>
        {
            this.camera.aspect = this.sizes.viewport.width / this.sizes.viewport.height
            this.camera.updateProjectionMatrix()

            this.renderer.setSize(this.sizes.viewport.width, this.sizes.viewport.height)

            if(this.useComposer)
            {
                this.passes.render.setSize(this.sizes.viewport.width, this.sizes.viewport.height)
                this.passes.smaa.setSize(this.sizes.viewport.width, this.sizes.viewport.height)
                this.composer.setSize(this.sizes.viewport.width, this.sizes.viewport.height)
            }
        })
    }

    /**
     * Set world
     */
    setWorld()
    {
        // Origami
        this.origami = new Origami({
            source: this.loading.loader.resources.model
        })
        this.scene.add(this.origami.container)

        // Floor
        this.floor = {}
        this.floor.material = new THREE.MeshStandardMaterial({ color: 0xdddddd, metalness: 0, roughness: 0.7 })
        this.floor.geometry = new THREE.PlaneBufferGeometry(10, 10, 1, 1)
        this.floor.mesh = new THREE.Mesh(this.floor.geometry, this.floor.material)
        this.floor.mesh.receiveShadow = true
        this.floor.mesh.rotation.x = - Math.PI * 0.5
        this.floor.mesh.position.y = - 0.1
        this.scene.add(this.floor.mesh)

        // Ambient light
        this.ambientLight = new THREE.AmbientLight(0xfbfcec, 0.8)
        this.scene.add(this.ambientLight)

        // Directional light
        this.directionalLight = new THREE.DirectionalLight(0xfbfcec, 0.2)

        this.directionalLight.shadow.mapSize.width = 2048
        this.directionalLight.shadow.mapSize.height = 2048
        this.directionalLight.shadow.camera.near = 0.1
        this.directionalLight.shadow.camera.far = 20
        this.directionalLight.shadow.radius = 3

        this.directionalLight.castShadow = true

        this.directionalLight.position.x = - 0.9
        this.directionalLight.position.y = 3
        this.directionalLight.position.z = 3

        this.scene.add(this.directionalLight)

        // this.directionalLightHelper = new THREE.DirectionalLightHelper(this.directionalLight, 5)
        // this.scene.add(this.directionalLightHelper)
    }

    /**
     * Destroy
     */
    destroy()
    {
        this.time.off('tick')
        this.sizes.off('resize')

        this.renderer.dispose()

        if(this.ui)
        {
            this.ui.destroy()
        }
    }
}
