import FBXLoader from './Loaders/FBXLoader.js'
import OBJLoader from './Loaders/OBJLoader.js'
import OBJXLoader from './Loaders/OBJXLoader.js'
import TDSLoader from './Loaders/TDSLoader.js'
import EventEmitter from './EventEmitter.js'

export default class Loader extends EventEmitter
{
    /**
     * Constructor
     */
    constructor()
    {
        super()

        this.resources = {}
        this.loadedCount = 0
        this.toLoadCount = 0
        this.ended = false
        this.regex = {}
        this.regex.image = new RegExp(/\.(gif|jpg|jpeg|tiff|png)$/i)
        this.regex.audio = new RegExp(/\.(mp3)$/i)
        this.regex.obj = new RegExp(/\.(obj)$/i)
        this.regex.fbx = new RegExp(/\.(fbx)$/i)
        this.regex['3ds'] = new RegExp(/\.(3ds)$/i)
        this.regex.objx = new RegExp(/\.(objx)$/i)
    }

    /**
     * Load
     */
    load(_source, _name, _type = '')
    {
        this.toLoadCount++

        /**
         * Image
         */
        if(this.regex.image.test(_source) || _type === 'image')
        {
            const image = new Image()

            image.addEventListener('load', () =>
            {
                this.resources[_name] = image
                this.loadedCount++

                this.trigger('load')

                this.testEnd()
            })

            image.addEventListener('error', () =>
            {
                console.log('error while loading', _name, _source)
                this.loadedCount++

                this.trigger('error')

                this.testEnd()
            })

            image.src = _source
        }

        /**
         * OBJX
         */
        else if(this.regex.objx.test(_source) || _type === 'objx')
        {
            // Create loader
            if(!this.objxLoader)
            {
                this.objxLoader = new OBJXLoader()
            }

            this.objxLoader.load(
                _source,
                (object) =>
                {
                    // window.setTimeout(() =>
                    // {
                        this.resources[_name] = object
                        this.loadedCount++

                        this.trigger('load')

                        this.testEnd()
                    // }, 2000 + Math.random() * 2000)
                },
                () => {},
                () =>
                {
                    console.log('error while loading', _name, _source)
                    this.loadedCount++

                    this.trigger('error')

                    this.testEnd()
                }
            )
        }

        /**
         * FBX
         */
        else if(this.regex.fbx.test(_source) || _type === 'fbx')
        {
            // Create loader
            if(!this.fbxLoader)
            {
                this.fbxLoader = new FBXLoader()
            }

            this.fbxLoader.load(
                _source,
                (object) =>
                {
                    this.resources[_name] = object
                    this.loadedCount++

                    this.trigger('load')

                    this.testEnd()
                },
                () => {},
                () =>
                {
                    console.log('error while loading', _name, _source)
                    this.loadedCount++

                    this.trigger('error')

                    this.testEnd()
                }
            )
        }

        /**
         * OBJ
         */
        else if(this.regex.obj.test(_source) || _type === 'obj')
        {
            // Create loader
            if(!this.objLoader)
            {
                this.objLoader = new OBJLoader()
            }

            this.objLoader.load(
                _source,
                (object) =>
                {
                    this.resources[_name] = object
                    this.loadedCount++

                    this.trigger('load')

                    this.testEnd()
                },
                () => {},
                () =>
                {
                    console.log('error while loading', _name, _source)
                    this.loadedCount++

                    this.trigger('error')

                    this.testEnd()
                }
            )
        }

        /**
         * 3DS
         */
        else if(this.regex['3ds'].test(_source) || _type === '3ds')
        {
            // Create loader
            if(!this.tdsLoader)
            {
                this.tdsLoader = new TDSLoader()
            }

            this.tdsLoader.load(
                _source,
                (object) =>
                {
                    this.resources[_name] = object
                    this.loadedCount++

                    this.trigger('load')

                    this.testEnd()
                },
                () => {},
                () =>
                {
                    console.log('error while loading', _name, _source)
                    this.loadedCount++

                    this.trigger('error')

                    this.testEnd()
                }
            )
        }
    }

    /**
     * Test end
     */
    testEnd()
    {
        if(this.loadedCount === this.toLoadCount)
        {
            this.ended = true
            this.trigger('end')
        }
    }
}
