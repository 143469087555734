import * as THREE from 'three'

export default class OBJXLoader
{
    constructor(_manager)
    {
        this.manager = _manager !== undefined ? _manager : THREE.DefaultLoadingManager;
    }

    load(url, onLoad, onProgress, onError)
    {
        const loader = new THREE.FileLoader(this.manager);
        // loader.setPath(this.path);
        loader.load(
            url,
            (text) =>
            {
                onLoad(this.parse(text))
            },
            onProgress,
            onError
        )
    }

    parse(input)
    {
        // Parse and extract each vertice and edge
        const vertices = []
        const edges = []
        const lines = input.split('\n')

        for(const line of lines)
        {
            // Vertices
            const match = line.match(/^v(?:\s+(-?\d+.\d+))(?:\s+(-?\d+.\d+))(?:\s+(-?\d+.\d+))/)
            if(match)
            {
                vertices.push([parseFloat(match[1]), parseFloat(match[2]), parseFloat(match[3])])
            }

            // Edges
            else
            {
                const match = line.match(/^l\s(\d+)\s(\d+)/)
                if(match)
                {
                    edges.push([parseInt(match[1]), parseInt(match[2])])
                }
            }
        }

        // Geometry
        const bufferGeometry = new THREE.BufferGeometry()
        const geometryVertices = []

        for(const edge of edges)
        {
            geometryVertices.push(...vertices[edge[0] - 1], ...vertices[edge[1] - 1])
        }

        // build geometry
        bufferGeometry.addAttribute('position', new THREE.Float32BufferAttribute(geometryVertices, 3))

        // Return
        return { geometry: bufferGeometry }
    }
}
