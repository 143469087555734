import * as THREE from 'three'
import { TweenLite, TimelineLite, Power3 } from 'gsap'

export default class Origami
{
    constructor(_options = {})
    {
        this.source = _options.source

        // Set up
        this.container = new THREE.Object3D()
        // this.material = new THREE.MeshNormalMaterial({ side: THREE.DoubleSide, morphTargets: true, morphNormals: true })
        // this.material = new THREE.MeshBasicMaterial({ wireframe: true, side: THREE.DoubleSide, morphTargets: true })
        this.material = new THREE.MeshStandardMaterial({ side: THREE.DoubleSide, morphTargets: true, morphNormals: true, color: 0xffffff, metalness: 0, roughness: 0.7 })

        // Geometries
        this.geometries = []

        this.source.children.sort((a, b) =>
        {
            return a.name > b.name
        })

        for(const _child of this.source.children)
        {
            // For OBJ
            const geometry = new THREE.Geometry()
            geometry.fromBufferGeometry(_child.geometry)
            this.geometries.push(geometry)

            // For 3DS
            // _child.geometry.rotateX(Math.PI * 0.5)
            // _child.geometry.rotateY(Math.PI * 0.5)
            // this.geometries.push(_child.geometry)
        }

        // // For OBJ
        // this.geometries.reverse()

        // Geometry
        this.geometry = this.geometries[0].clone()

        let i = 0
        for(const _geometry of this.geometries)
        {
            const morphTarget = {}
            morphTarget.name = `test-${i}`
            morphTarget.vertices = _geometry.vertices

            this.geometry.morphTargets.push(morphTarget)

            i++
        }
        this.geometry.computeMorphNormals()
        this.geometry = new THREE.BufferGeometry().fromGeometry(this.geometry)

        // Mesh
        this.mesh = new THREE.Mesh(this.geometry, this.material)
        // this.mesh.receiveShadow = true
        this.mesh.castShadow = true
        this.container.add(this.mesh)

        window.mesh = this.mesh

        // Animation
        this.animation = {}
        this.animation.progress = 0
        this.animation.timeline = new TimelineLite({
            onUpdate: () =>
            {
                for(let i = 0; i < this.mesh.morphTargetInfluences.length; i++)
                {
                    let value = 1 - Math.abs(i - this.animation.progress)
                    value = Math.min(Math.max(value, 0), 1)
                    this.mesh.morphTargetInfluences[i] = value
                }
            },
            onComplete: () =>
            {
                this.animation.timeline.reverse()
            },
            onReverseComplete: () =>
            {
                this.animation.timeline.play()
            }
        })
        this.animation.timeline.add(TweenLite.to(this.animation, 1, { progress: 2, ease: Power3.easeInOut }))
        this.animation.timeline.add(TweenLite.to(this.animation, 0.3, { progress: 2, ease: Power3.easeInOut })) // Pause
        this.animation.timeline.add(TweenLite.to(this.animation, 1, { progress: 6, ease: Power3.easeInOut }))
        this.animation.timeline.add(TweenLite.to(this.animation, 0.3, { progress: 6, ease: Power3.easeInOut })) // Pause
    }
}
